import React, { Component, useEffect, useState } from "react";
import "./style.css";
import logov2 from "../../../assets/img/logo-v2.svg";
import "react-datepicker/dist/react-datepicker.css";
import rightnexticon from "../../../assets/img/rightnexticon.svg";
import OwnerDetails from "../../components/inviteForm/OwnerDetails";
import CompanyDetail from "../../components/inviteForm/CompanyDetail";
import rightbackicon from "../../../assets/img/rightbackicon.svg";
import Requested from "../../components/inviteForm/Requested";
import { ApiRoutes, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { registerValidator } from "../../../validators";
import { Form, Spinner } from "react-bootstrap";
import Expired from "../../components/inviteForm/Expired";
import Submitted from "../../components/inviteForm/Submitted";
import Preview from "../../components/inviteForm/Preview";
import SubmitButton from "../../components/form/SubmitButton";
import moment from "moment";
import { Link } from "react-router-dom";
const countryState = require("../../../common/countryState.json");

const Invitation = ({}) => {
  const queryParams = new URLSearchParams(window.location.search);
  // never remove "123" from line no.(24)
  const token = queryParams.get("token") || "123";
  const checkToken = queryParams.get("token");
  const [isUploading, setIsUploading] = useState({});
  const [step, setStep] = useState(1);
  const [editStep, setEditStep] = useState(0);
  const [isTokenValid, SetIsTokenValid] = useState(false);
  const [isTokenStatus, SetIsTokenStatus] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [isLoading, SetLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [islastNameReadOnly, setLastNameReadOnly] = useState(false);
  const [isFirsttNameReadOnly, setFirstNameReadOnly] = useState(false);

  const [userRegisterData, setUserRegisterData] = useState({
    email: "",
    password: "",
    companyName: "",
    website: "",
    businessRegNo: "",
    VATNumber: "",
    address1: "",
    address2: "",
    postCode: "",
    phone: "",
    brandName: "",
    storeLogo: "",
    errors: null,
    islogged: false,
    isLoading: false,
    insideUSACanada: true,
    businessRegNo_image: "",
    VATNumber_image: "",
    TradeMarkCertificateNo: "",
    TradeMarkCertificateNo_image: "",
    state: null,
    country: null,
    storeName: "",
    countryCode: "",
    companyId: "",
  });

  const [ownerData, setOwnerData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    gender: "",
    profile_picture: "",
    country: "",
    errors: null,
    islogged: false,
    isLoading: true,
  });

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  const verifyToken = async (token) => {
    if (!checkToken) {
      SetIsTokenValid(true);
      SetIsTokenStatus("Invited");
      SetLoading(false);
    } else {
      var url = ApiRoutes.REGISTER_TOKEN_VERIFY.url + "token=" + token;
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.REGISTER_TOKEN_VERIFY.service,
        url,
        ApiRoutes.REGISTER_TOKEN_VERIFY.method,
        ApiRoutes.REGISTER_TOKEN_VERIFY.authenticate,
        undefined
      );

      if (response && response.code === 200) {
        SetIsTokenValid(true);
        SetIsTokenStatus(response?.data?.data?.user?.status);
        if (response.data && response.data.data) {
          setUserRegisterData({
            ...userRegisterData,
            email: response?.data?.data?.user?.email,
            insideUSACanada: response?.data?.data?.store?.insideUSACanada,
            companyName: response?.data?.data?.store?.companyName,
            companyId: response?.data?.data?.store?.companyId,
            countryCode: response?.data?.data?.store?.countryCode
              ? {
                  label: response?.data?.data?.store?.countryCode,
                  value: response?.data?.data?.store?.countryCode,
                }
              : +1,
            state: response?.data?.data?.store?.state
              ? {
                  label: response?.data?.data?.store?.state,
                  value: response?.data?.data?.store?.state,
                }
              : response?.data?.data?.store?.state,
            country: response?.data?.data?.store?.country
              ? {
                  label: response?.data?.data?.store?.country,
                  value: response?.data?.data?.store?.country,
                }
              : response?.data?.data?.store?.country,
            // email: response.data.data.email,
          });
          setOwnerData({
            ...ownerData,
            first_name: response?.data?.data?.user?.first_name,
            last_name: response?.data?.data?.user?.last_name,
            gender: response?.data?.data?.user?.gender
              ? {
                  label: response?.data?.data?.user?.gender,
                  value: response?.data?.data?.user?.gender,
                }
              : response?.data?.data?.user?.gender,
            country: response?.data?.data?.user?.other_info?.country
              ? {
                  label: response?.data?.data?.user?.other_info?.country,
                  value: response?.data?.data?.user?.other_info?.country,
                }
              : response?.data?.data?.user?.other_info?.country,
            birth_date: response?.data?.data?.user?.birth_date
              ? new Date(response?.data?.data?.user?.birth_date)
              : null || "",
          });
          if (response?.data?.data?.user?.last_name.trim().length > 0) {
            setLastNameReadOnly(true);
          }
          if (response?.data?.data?.user?.first_name.trim().length > 0) {
            setFirstNameReadOnly(true);
          }
        }

        if (response?.data?.data?.user?.status === "Submitted") {
          toast.success(response.data.message);
        } else if (
          response?.data?.data?.user?.status !== "Invited" &&
          response?.data?.data?.user?.status !== "Submitted"
        ) {
          toast.success(response.data.message);
        } else {
        }
      } else if (response && response.code === 404) {
        SetIsTokenValid(false);
        if (response?.data?.message) toast.error(response.data.message);
      } else {
        SetIsTokenValid(false);
        if (response?.data?.message) toast.error(response.data.message);
      }
      SetLoading(false);
    }
  };

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;
    if (name === "last_name" && islastNameReadOnly) {
      return;
    }
    if (name === "first_name" && isFirsttNameReadOnly) {
      return;
    }

    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setOwnerData({
        ...ownerData,
        [name]: type === "checkbox" ? checked : value.trimLeft(),
        errors: { ...ownerData.errors, [name]: "" },
      });
    }
  };

  const onlyNumbers = (str) => {
    return /^[0-9.,]+$/.test(str);
  };

  const handleCompanyChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    if (type === "tel" && value.length > 0) {
      if (!onlyNumbers(value)) return false;
    }
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value && name === "companyName")) {
      setUserRegisterData({
        ...userRegisterData,
        [name]: type === "checkbox" ? checked : value.trimLeft(),
        errors: { ...userRegisterData.errors, [name]: "" },
      });
    }
  };
  const handleSubmitStep1 = async (event) => {
    event && event.preventDefault();
    let data = {
      first_name: ownerData?.first_name,
      last_name: ownerData?.last_name,
      gender: ownerData?.gender ? ownerData?.gender.label : "",
      birth_date: moment(ownerData?.birth_date).toISOString(true),
      profile_picture: ownerData?.profile_picture,
      country: ownerData?.country ? ownerData?.country.label : "",
    };

    let { isValid, errors } = registerValidator(data);

    if (isValid) {
      setOwnerData(data);
      setStep(2);
    } else {
      setOwnerData({
        errors,
      });
      return;
    }
    if (editStep) {
      setStep(3);
    }
  };

  const handleSubmitStep2 = async (event) => {
    event && event.preventDefault();
    let data = {
      email: userRegisterData?.email,
      companyName: userRegisterData?.companyName,
      website: userRegisterData?.website,
      businessRegNo: userRegisterData?.businessRegNo,
      VATNumber: userRegisterData?.VATNumber,
      address1: userRegisterData?.address1,
      address2: userRegisterData?.address2,
      postCode: userRegisterData?.postCode,
      phone: userRegisterData?.phone,
      brandName: userRegisterData?.brandName,
      TradeMarkCertificateNo: userRegisterData?.TradeMarkCertificateNo,
      storeLogo: userRegisterData?.storeLogo,
      businessRegNo_image: userRegisterData?.businessRegNo_image,
      VATNumber_image: userRegisterData?.VATNumber_image,
      TradeMarkCertificateNo_image:
        userRegisterData?.TradeMarkCertificateNo_image,
      state: userRegisterData?.state ? userRegisterData?.state.label : "",
      country: userRegisterData?.country ? userRegisterData?.country.label : "",
      storeName: userRegisterData?.storeName,
      countryCode: userRegisterData?.countryCode
        ? userRegisterData?.countryCode.label
        : "",
      insideUSACanada: userRegisterData?.insideUSACanada,
      companyId: userRegisterData?.companyId,
    };

    let { isValid, errors } = registerValidator(data);
    if (isValid) {
      setUserRegisterData(data);
      setStep(3);
    } else {
      setUserRegisterData({
        errors: errors,
      });
      return;
    }
  };

  const handleSubmitStep3 = async () => {
    setIsAdding(true);
    delete ownerData.isLoading;
    delete ownerData.islogged;
    delete ownerData.errors;
    let combinePayload = ownerData;
    delete userRegisterData.isLoading;
    delete userRegisterData.islogged;
    delete userRegisterData.errors;
    combinePayload.storeData = userRegisterData;
    combinePayload.token = token;
    combinePayload.email = userRegisterData.email;

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REGISTER.service,
      ApiRoutes.REGISTER.url,
      ApiRoutes.REGISTER.method,
      ApiRoutes.REGISTER.authenticate,
      undefined,
      combinePayload
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      setIsAdding(false);
    } else {
      setIsAdding(false);
      setRequestId(response?.data?.data?.profileData?.applicationNumber);
      setStep(4);
      toast.success("Your details have been successfully submitted. Please check your email for further updates.");
    }
  };

  const handleDateChange = (date) => {
    setOwnerData({
      ...ownerData,
      birth_date: date,
    });
  };

  const onSelect = (label) => {
    if (label.value) {
      setOwnerData({
        ...ownerData,
        gender: label,
      });
    }
  };

  const onCodeSelect = (label) => {
    if (label.value) {
      setUserRegisterData({
        ...userRegisterData,
        countryCode: label,
      });
    }
  };

  const onCountrySelect = (name, identify) => {
    if (name) {
      if (identify === "owner") {
        setOwnerData({ ...ownerData, country: name });
      } else if (identify === "company") {
        setUserRegisterData({ ...userRegisterData, country: name });
      }
    }
  };

  const onStateSelect = (name) => {
    if (name) {
      setUserRegisterData({ ...userRegisterData, state: name });
    }
  };

  const handleBack = () => {
    setOwnerData({
      ...ownerData,
      birth_date: ownerData?.birth_date
        ? new Date(ownerData?.birth_date)
        : null || "",
      gender: ownerData?.gender
        ? {
            label: ownerData?.gender,
            value: ownerData?.gender,
          }
        : "",
      country: ownerData.country
        ? {
            label: ownerData?.country,
            value: ownerData?.country,
          }
        : "",
    });

    setStep(1);
  };

  // On file select
  const onFileChange = async (event, identify, param_type) => {
    event.persist();
    const {
      target: { files },
    } = event;

    const file = files[0];
    if (file && !file.type.match("image.*")) {
      toast.error("Please select only image file");
      return;
    }

    await uploadPicture(file, identify, param_type);
    event.target.value = "";
  };

  const uploadPicture = async (data, identify, param_type) => {
    setIsUploading(true);
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (path) {
        if (identify === "owner") {
          setOwnerData({ ...ownerData, [param_type]: path });
        } else if (identify === "company") {
          setUserRegisterData({ ...userRegisterData, [param_type]: path });
        }
      }
    }
    setIsUploading(false);
  };

  const isEditStep = (step) => {
    if (step === 1) {
      setOwnerData({
        ...ownerData,
        birth_date: ownerData?.birth_date
          ? new Date(ownerData?.birth_date)
          : null || "",
        gender: ownerData?.gender
          ? {
              label: ownerData?.gender,
              value: ownerData?.gender,
            }
          : "",
        country: ownerData.country
          ? {
              label: ownerData?.country,
              value: ownerData?.country,
            }
          : "",
      });
    } else {
      setUserRegisterData({
        ...userRegisterData,
        insideUSACanada: userRegisterData?.insideUSACanada,
        companyId: userRegisterData?.companyId,
        state: userRegisterData?.state
          ? {
              label: userRegisterData?.state,
              value: userRegisterData?.state,
            }
          : "",
        country: userRegisterData?.country
          ? {
              label: userRegisterData?.country,
              value: userRegisterData?.country,
            }
          : "",
        countryCode: userRegisterData?.countryCode
          ? {
              label: userRegisterData?.countryCode,
              value: userRegisterData?.countryCode,
            }
          : "",
      });
    }

    setEditStep(step);
    setStep(step);
  };

  const isSubmited = (step) => {
    setStep(step);
  };

  let allCountry = [];
  // countryState.forEach((element) => {
  //   allCountry.push({ label: element.name, value: element.name });
  // });
  countryState.forEach((element) => {
    if (
      element.name === "United States" ||
      element.name === "Canada" ||
      element.name === "Mexico" ||
      element.name === "The Bahamas"
    ) {
      if (userRegisterData?.insideUSACanada === true) {
        allCountry.push({
          label: element.name,
          value: element.name,
        });
      }
    } else {
      if (userRegisterData?.insideUSACanada === false) {
        allCountry.push({
          label: element.name,
          value: element.name,
        });
      }
    }
  });

  const loadCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const handleSelectChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setUserRegisterData({
      ...userRegisterData,
      [name]: value && value === "yes" ? true : false,
    });
  };
  return (
    <>
      <section
        className={step === 4 ? "vh-100 invite_section" : "invite_section"}
      >
        <div className="container-fluid">
          {step === -1 ? (
            <Submitted />
          ) : !isLoading ? (
            isTokenValid ? (
              isTokenStatus === "Invited" ? (
                <div className="row">
                  <div className="col-md-12 customGrid-xxl">
                    <div
                      className={step > 3 ? "dispalyCard formCard" : "formCard"}
                    >
                      <div className="cardHeader">
                        <img src={logov2} />
                      </div>
                      <div className="cardBody">
                        <h3 className="registerMain">Register with us</h3>

                        <div className="steps_mainbox d-flex align-items-center justify-content-between">
                          <div
                            className={
                              step > 0 ? "steps_box active" : "steps_box"
                            }
                          >
                            <div className="button">1</div>
                            <h4>Owner Details</h4>
                          </div>
                          <div
                            className={
                              step > 1 ? "steps_box active" : "steps_box"
                            }
                          >
                            <div className="button">2</div>
                            <h4>Company Details</h4>
                          </div>
                          <div
                            className={
                              step > 2 ? "steps_box active" : "steps_box"
                            }
                          >
                            <div className="button">3</div>
                            <h4>Preview</h4>
                          </div>
                        </div>
                        <div className="formStepsBox">
                          {step === 1 && (
                            <OwnerDetails
                              islastNameReadOnly={islastNameReadOnly}
                              ownerData={ownerData}
                              onChange={handleChange}
                              onDateChange={handleDateChange}
                              handleSubmit={handleSubmitStep1}
                              onSelect={onSelect}
                              onCountrySelect={onCountrySelect}
                              onFileChange={onFileChange}
                              editStep={editStep}
                              isFirsttNameReadOnly={isFirsttNameReadOnly}
                            />
                          )}
                          {step === 2 && (
                            <CompanyDetail
                              userRegisterData={userRegisterData}
                              allCountry={allCountry}
                              onChange={handleCompanyChange}
                              handleSubmit={handleSubmitStep2}
                              handleBack={handleBack}
                              onCountrySelect={onCountrySelect}
                              onStateSelect={onStateSelect}
                              onFileChange={onFileChange}
                              editStep={editStep}
                              onCodeSelect={onCodeSelect}
                              loadCodeData={loadCodeData}
                              handleSelectChange={handleSelectChange}
                              checkToken={checkToken}
                            />
                          )}
                          {step === 3 && (
                            <Preview
                              isEditStep={isEditStep}
                              ownerData={ownerData}
                              userRegisterData={userRegisterData}
                              editStep={editStep}
                              handleSubmit={handleSubmitStep3}
                            />
                          )}
                          {step === 4 && (
                            <Requested
                              requestId={requestId}
                              isSubmited={isSubmited}
                            />
                          )}
                          {step === 3 && (
                            <div
                              // onClick={handleSubmitStep3}
                              className="col-md-12 text-center position-relative"
                              style={{ marginTop: "30px" }}
                            >
                              <SubmitButton
                                className={"submitButton mt-0"}
                                text={"Submit"}
                                isLoading={isAdding}
                                onSubmit={handleSubmitStep3}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            Already have an account?{" "}
                            <Link to={"/login"}>Login Here</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // <TrackRequest />
                <Submitted />
              )
            ) : (
              <Expired />
            )
          ) : (
            <Spinner
              animation="border"
              variant="primary"
              className="register_loading"
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Invitation;
