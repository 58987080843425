import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { DEFAULT_DATE_FORMAT } from "../../../config";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import { Button, Dropdown } from "react-bootstrap";
import { BatchHistoryLoader } from "../../components/loader/Resusable/BatchHistory";
import InputCheckbox from "../form/InputCheckbox";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import viewIcon from "../../../assets/img/batchExport.svg";
import printIcon from "../../../assets/img/batchPrint.svg";
import trashIcon from "../../../assets/img/delete_icon.svg";
import markInActive from "../../../assets/img/inactive_icon.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import moreIcon from "../../../assets/img/more.svg";
import noData from "../../../assets/img/noData.svg";

const BatchHistoryComponent = ({
  batchHistory,
  isLoading,
  activeBatchGroup,
  onExport,
  onView,
  onPrint,
  onBatchHistoryDelete,
  sortBy,
  order,
  setFilter,
  inactiveBatch,
  isActive,
  isExporting,
  filtersOption
}) => {
  console.log("activeBatchGroup", batchHistory);
  // const filtersOption = [
  //   {
  //     label: "CSV",
  //     value: "csv",
  //   },
  //   {
  //     label: "PDF",
  //     value: "pdf",
  //   },
  //   {
  //     label: "Excel",
  //     value: "excel",
  //   },
  // ];

  const batchHistoryPrint = async (
    batchId,
    batchGroup,
    unitCount,
    first_name
  ) => {
    let mapObj = {
      ":id": batchId,
      ":unit": unitCount,
    };
    const { value } = await SweetAlertComponent({
      title: "Are you Sure?",
      text: `This will be a duplicated code and may already have been used.`,
      confirmButtonText: "Print",
      reverseButtons: true,
    });
    if (!value) {
      return;
    }
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    window.open(
      `${AppRoutes.PRINT_BATCH.replace(re, function (matched) {
        return mapObj[matched.toLowerCase()];
      })}?group=${batchGroup}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="tableFilter batch_history d-flex item-start justify-content-between pl-0 mb-0 mt-0">
        <h3 className="batch-historyHead">Batch History</h3>
      </div>

      <div className="">
        {isLoading ? (
          <BatchHistoryLoader />
        ) : (
          <>
            {" "}
            <div className="tableCard card mb-3" id={"batch-history"}>
              <div className="tabel-outer">
                {/* className="table-responsive fixed-height-table"  */}
                <div
                  className={
                    batchHistory && batchHistory.length > 0
                      ? "table-responsive fixed-height-table"
                      : "table-responsive"
                  }
                >
                  <table
                    className="table batchTable intableCheckbox batchHistoryTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr className="batchesHeader">
                        {/* <th>
                      <InputCheckbox 
                        id='customCheck'
                        name='islogged'
                        value=""
                      />
                    </th> */}
                        <th style={{ minWidth: "240px" }}>
                          EXPORTED BY
                          <span className="cursor-pointer">
                            <img
                              src={
                                sortBy === "first_name"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                setFilter(
                                  "first_name",
                                  sortBy === "first_name"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th style={{ minWidth: "205px" }}>
                          Unit COUNT
                          <span className="cursor-pointer">
                            <img
                              src={
                                sortBy === "count"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                setFilter(
                                  "count",
                                  sortBy === "count"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th style={{ minWidth: "205px" }} className="">
                          Export date
                          <span className="cursor-pointer">
                            <img
                              src={
                                sortBy === "orderDate"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                setFilter(
                                  "orderDate",
                                  sortBy === "orderDate"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchHistory && batchHistory.length ? (
                        batchHistory.map((batch, index) => (
                          <tr key={index} className="btachRow history">
                            {/* <td className='width-0'>
                          <InputCheckbox 
                            id='customCheck'
                            name='islogged'
                            value=""
                          />
                        </td> */}
                            <td className="pl-54px">
                              <span className="profile_holder_history">
                                {batch.client
                                  ? batch.client.username === "phl@clarity.com"
                                    ? "P"
                                    : batch.storeName.substring(0, 1)
                                  : "-"}
                              </span>
                              <span className="textContol">
                                {batch.client
                                  ? batch.client.username === "phl@clarity.com"
                                    ? "Professional Hair Labs"
                                    : batch.storeName
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="textContol">{batch.count}</span>
                            </td>
                            <td>
                              <span className="textContol">
                                {batch.orderDateFormat
                                  ? batch.orderDateFormat
                                  : batch.orderDate
                                  ? moment(batch.orderDate).format(
                                      DEFAULT_DATE_FORMAT
                                    )
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex align-items-center batchHistory_table">
                                <Dropdown
                                  className=""

                                  // onClick={() => {
                                  //   // scrollWin(0, 7500);
                                  //   // window.scrollBy(0, 7500);
                                  //   const cardSection = document.getElementById(
                                  //     'content',
                                  //   );
                                  //   let valuess = cardSection.getBoundingClientRect();
                                  //   console.log(valuess, 'valuess');
                                  //   const scrollTo = cardSection
                                  //     ? cardSection.getBoundingClientRect().top +
                                  //       window.scrollY -
                                  //       75
                                  //     : 0;
                                  //   window.scroll({
                                  //     top: scrollTo,
                                  //     behavior: 'smooth',
                                  //   });
                                  //   console.log(
                                  //     'inside menu click',
                                  //     cardSection,
                                  //     cardSection.scrollTop,
                                  //   );
                                  //   // cardSection.scrollTop = 0;
                                  // }}
                                >
                                  <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className={"exportBatchBtn"}
                                    data-boundary="window"
                                    disabled={isExporting || batch.isInactive}
                                    // disabled={
                                    //   activeBatchGroup === batch.batchGroup
                                    // }
                                  >
                                    {activeBatchGroup === batch.batchGroup ? (
                                      <>
                                        <i className="fa fa-spinner fa-spin test-spin"></i>
                                        &nbsp;Exporting
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={viewIcon}
                                          alt=""
                                          className={"img-main"}
                                        />
                                        Export
                                      </>
                                    )}
                                  </Dropdown.Toggle>
                                  <div className="customDropdownBox">
                                    <Dropdown.Menu className="shadow dropdown-menu dropdown-staff">
                                      {filtersOption.map(
                                        ({ label, value }, index) => (
                                          <Dropdown.Item
                                            as="div"
                                            key={index}
                                            onClick={() =>
                                              onExport(
                                                value,
                                                batch.client
                                                  ? batch.client._id
                                                  : "",
                                                batch.orderDate,
                                                batch.batchGroup
                                              )
                                            }
                                          >
                                            {label}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </div>
                                </Dropdown>
                                {/* <div className='btn btn-secondary th-btn edit-tb'>
                              Export As

                              <img src={filterarrowIcon} alt='' className ='img-leftIcon' />
                            </div> */}
                                <Button
                                  variant=""
                                  className="printButton mx-2"
                                  onClick={() =>
                                    batchHistoryPrint(
                                      batch.batchId,
                                      batch.batchGroup,
                                      batch.count,
                                      batch.first_name
                                    )
                                  }
                                  disabled={isExporting || batch.isInactive}
                                >
                                  <img
                                    src={printIcon}
                                    alt=""
                                    className={"img-main"}
                                  />
                                  Print
                                </Button>

                                <Dropdown className="">
                                  <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className="InactiveDropdown"
                                  >
                                    <img src={moreIcon} alt="" />
                                  </Dropdown.Toggle>
                                  <div className="customDropdownBox">
                                    <Dropdown.Menu className="shadow dropdown-menu dropdown-staff">
                                      <Dropdown.Item
                                        as="div"
                                        className="dropItem"
                                        disabled={batch.isInactive}
                                        onClick={() =>
                                          inactiveBatch(
                                            batch.batchId,
                                            batch.batchGroup
                                          )
                                        }
                                      >
                                        <img
                                          src={markInActive}
                                          alt=""
                                          className={"img-main mr-2"}
                                        />
                                        {batch.isInactive
                                          ? "Inactive"
                                          : "Mark as Inactive"}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="div"
                                        className="dropItem"
                                        disabled={batch.isInactive}
                                        onClick={() =>
                                          onBatchHistoryDelete(
                                            batch.batchId,
                                            batch.batchGroup
                                          )
                                        }
                                      >
                                        <img
                                          src={trashIcon}
                                          alt=""
                                          className={"img-main mr-2"}
                                        />
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </div>
                                </Dropdown>
                                {/* <Button variant="" className='markActiveButton mr-2' onClick={onPrint}>
                                  <img src={markInActive} alt='' className={"img-main"} />
                                  Mark as Inactive
                              </Button>
                              <Button variant="" className='deleteButton' onClick={onDelete}>
                                  <img src={trashIcon} alt='' className={"img-main"} />
                                Delete
                              </Button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={8}
                            className={
                              "text-center image-no-data-xs pt-5 pb-2 "
                            }
                          >
                            <>
                              <img
                                style={{ width: "120px" }}
                                src={noData}
                                alt=""
                              />
                              <p className="nodatatextp">
                                Batch is not assigned to anyone yet.
                              </p>
                            </>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BatchHistoryComponent;
