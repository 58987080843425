import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import moment from "moment";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import BatchDetailComponent from "../../components/batch/BatchDetailComponent";
import BatchHistoryComponent from "../../components/batch/BatchHistoryComponent";
import PrintBatchModal from "./PrintBatch";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../components/sweetAlertComponent";
// import { EditBatchError } from '../../components/sweetAlertComponent/EditBatchError'
// import './batches.css';

class ViewBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchId: "",
      batchData: {},
      batchHistory: [],
      isLoading: false,
      isBatchExporting: false,
      activeBatchGroup: "",
      isHistoryLoading: false,
      editBatchError: false,
      printModal: false,
      sortBy: "orderDate",
      order: "desc",
      isActive: true,
      plan: {},
      filtersOption: [],
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ batchId: id }, () => {
        this.viewBatch();
        this.viewBatchHistory();
        this.getSubDashboard();
      });
    }
  };

  getSubDashboard = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBSCRIPTION_DASHBOARD.service,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.url,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.method,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      const data = response?.data;
      const plan =
        data.subscriptionList.length > 0
          ? data.subscriptionList[0].subscription
          : {};
          const filtersOption = []
          if(plan.export_type && plan.export_type == 'CSV'){
            filtersOption.push({
              label: "CSV",
              value: "csv",
            })
          }else if(plan.export_type && plan.export_type == 'PDF'){
            filtersOption.push({
              label: "PDF",
              value: "pdf",
            })
          }
      this.setState({
        isLoading: false,
        plan,
        filtersOption
      });
    }
  };

  viewBatch = async () => {
    const { batchId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_BATCH.service,
      ApiRoutes.VIEW_BATCH.url.replace(":id", batchId),
      ApiRoutes.VIEW_BATCH.method,
      ApiRoutes.VIEW_BATCH.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batch },
      } = response.data;
      this.setState({
        batchData: batch,
      });
    }
  };
  viewBatchHistory = async () => {
    const { batchId, sortBy, order } = this.state;
    let data = {
      sortBy,
      order,
    };
    this.setState({
      isHistoryLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BATCH_history.service,
      ApiRoutes.BATCH_history.url.replace(":id", batchId),
      ApiRoutes.BATCH_history.method,
      ApiRoutes.BATCH_history.authenticate,
      data,
      undefined
    );
    this.setState({
      isHistoryLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      console.log(response.data);

      this.setState({
        batchHistory: data,
      });
    }
  };
  onBatchHistoryDelete = async (batchId, batchGroup) => {
    // If batch is already assigned to some clients
    // if (batchData.itemCount !== batchData.availableUnits) {
    //   this.setState({
    //     deleteBatchError: true,
    //   });
    //   await SweetAlertComponent({
    //     // title: 'Are you sure?',
    //     text: `This batch has been assigned to some client.`,
    //     confirmButtonText: "OK",
    //     showCancelButton: false,
    //   });
    // } else {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to delete this Batch history?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_BATCH_HISTORY.service,
      ApiRoutes.DELETE_BATCH_HISTORY.url,
      ApiRoutes.DELETE_BATCH_HISTORY.method,
      ApiRoutes.DELETE_BATCH_HISTORY.authenticate,
      undefined,
      { batchId, batchGroup }
    );
    // this.setState({
    //   id: "",
    // });
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await this.viewBatchHistory();
    }
    // }
  };
  onEdit = async () => {
    const { batchData } = this.state;
    if (batchData.itemCount !== batchData.availableUnits) {
      this.setState({
        editBatchError: true,
      });
      await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `This batch has been assigned to some client.`,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      this.props.history.push(
        AppRoutes.EDIT_BATCH.replace(":id", batchData.id)
      );
    }
  };
  onEditErrorClose = () => {
    this.setState({
      editBatchError: false,
    });
  };
  onExport = async (type, clientId, orderDate, activeBatchGroup) => {
    const { batchId } = this.state;
    let blobType, extension;
    let data = { type, orderDate, batchGroup: activeBatchGroup };
    if (clientId) {
      this.setState({
        activeBatchGroup,
      });
      data = {
        ...data,
        clientId,
      };
    } else {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_BATCH.service,
      ApiRoutes.EXPORT_BATCH.url.replace(":id", batchId),
      ApiRoutes.EXPORT_BATCH.method,
      ApiRoutes.EXPORT_BATCH.authenticate,
      data,
      undefined,
      type !== "pdf" ? "blob" : ""
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    // this.inactiveBatch(batchId, activeBatchGroup)
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }

      // pdf.create(src, options).toFile('./businesscard.pdf', function(err, res) {
      //   if (err) return console.log(err);
      //   console.log(res); // { filename: '/app/businesscard.pdf' }
      // });

      // let bufferData;
      // console.log('src  ---> ', src);
      // console.log('options   ---> ', options);

      // pdf.create(src, options).toBuffer(function (err, buffer) {
      //   bufferData = buffer;
      //   console.log('bufferData   ----> ', bufferData);
      //   return res.status(200).send(bufferData);
      // });

      if (type === "pdf") {
        const promises = [];
        console.log(response);
        response.data.forEach(async (item) => {
          promises.push(QRCode.toDataURL(item.id.toString()));
        });
        let src = `<div class='container'><div class='row'><span style="display: flex;font-size:12px;"><div style="float:left;">${
          response.data && response.data.length
            ? moment(response.data[0].orderDate).format("MM/DD/YYYY")
            : "-"
        }</div><div style="text-align: center;">Print Batch Codes</div></span>`;

        const doc = new jsPDF({
          orientation: "p",
          unit: "pt",
          format: "A4",
        });
        const outputs = await Promise.all(promises);
        console.log(outputs);
        outputs.forEach(
          (img) =>
            (src += `<span class='col-md-2'><img src=${img} style="width:25mm; height:25mm;"></img></span>`)
        );
        // let url =
        //   'https://staging.clarity.codes/batches/62f3caaccd8d945c5771538e/print/1?assignee=6034c8c10b98b46b5d48459e&group=823100b5-c1db-4154-a9e5-850c8707b708';
        // src += `<span style="color: #444;font-size:12px;"><div style="float:left;font-size:6px;"><a href=${url}>Print</a></div><div style="float:right;"><span>{{page}}</span>/<span>{{pages}}</span></div></div></div></div>`;

        doc.html(src, {
          callback: (doc) => {
            doc.save("Batch.pdf");
          },
        });
      } else {
        let blob = new Blob([response.data], {
          type: blobType,
        });
        FileSaver.saveAs(
          blob,
          `Batch.${extension}`
          // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
        );
      }
    }
  };

  inactiveBatch = async (batchId, batchGroup) => {
    let data = {
      batchId,
      batchGroup,
    };

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.INACTIVE_BATCH.service,
      ApiRoutes.INACTIVE_BATCH.url,
      ApiRoutes.INACTIVE_BATCH.method,
      ApiRoutes.INACTIVE_BATCH.authenticate,
      undefined,
      data
    );
    this.setState({
      isBatchExporting: false,
    });

    console.log("here is", data);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await this.viewBatchHistory();
    }
  };

  onPrint = () => {
    this.setState({
      printModal: true,
    });
  };
  // To close print batch modal
  handleClose = () => {
    this.setState({
      printModal: false,
    });
  };
  openPrintModal = () => {
    const { printModal, batchData } = this.state;
    return (
      <PrintBatchModal
        open={printModal}
        batchData={{
          ...batchData,
          _id: batchData.id,
          productName:
            batchData && batchData.productId
              ? batchData.productId.productName
              : "",
        }}
        handleClose={this.handleClose}
        fetchData={this.viewBatch}
        fetchHistory={this.viewBatchHistory}
      />
    );
  };
  customBreadCrumb = () => {
    const { isLoading, batchData } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      batchData.batchNumber
    );
  };
  setFilter = async (sortBy, order) => {
    await this.setState({
      sortBy: sortBy || "orderDate",
      order: order || "desc",
    });
    await this.viewBatchHistory();
  };

  render() {
    const {
      isLoading,
      isHistoryLoading,
      activeBatchGroup,
      isBatchExporting,
      batchData,
      batchHistory,
      sortBy,
      order,
      filtersOption,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid">
        <div className="tableFilter add_batch d-flex item-start justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
              search={state ? state.search : ""}
            />
          </h3>
        </div>

        <div className="row">
          <div className="col-md-12">
            <BatchDetailComponent
              batchData={batchData}
              isLoading={isLoading}
              isExporting={isBatchExporting}
              onEdit={this.onEdit}
              onPrint={this.onPrint}
              filtersOption={filtersOption}
              onExport={this.onExport}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <BatchHistoryComponent
              batchHistory={batchHistory}
              isLoading={isHistoryLoading}
              onExport={this.onExport}
              activeBatchGroup={activeBatchGroup}
              onPrint={this.onPrint}
              onBatchHistoryDelete={this.onBatchHistoryDelete}
              sortBy={sortBy}
              order={order}
              setFilter={this.setFilter}
              inactiveBatch={this.inactiveBatch}
              isActive={isHistoryLoading}
              isExporting={isBatchExporting}
              filtersOption={filtersOption}
            />
          </div>
        </div>
        {this.openPrintModal()}
        {/* <EditBatchError
          handleClose={this.onEditErrorClose}
          open={editBatchError}
        /> */}
      </div>
    );
  }
}

export default ViewBatch;
