import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { permissionValidator } from "../../../../validators/permission";
import AssignedPermissionsListComponent from "../../../components/staffs/Permission";
import { PermissionFormComponent } from "../../../components/staffs/Permission/PermissionFormComponent";
import * as qs from "query-string";
import "../manage-staff.css";

class ManageStaffPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: "",
      // Permission module related fields
      permission: [],
      selectedModule: null,
      permissionErrors: null,
      deletingModule: "", // to apply remove loader on particular index
      sortBy: "accessType",
      order: "desc",
      currentPage: 1,
      limit: 10,
      isAdding: false,
      module: null,
      accessType: null,
      subModules: null,
      newModule: {},
      accessTypeOptions : {}
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        staffId: id,
      },
      () => this.handleQueryParams()
    );
    this.getSubDashboard()
  };

  getSubDashboard = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBSCRIPTION_DASHBOARD.service,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.url,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.method,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      const data = response?.data;
      const plan =
        data.subscriptionList.length > 0
          ? data.subscriptionList[0].subscription
          : {};
          const accessTypeOptions = []
          if (plan.permission_type && plan.permission_type == "Read") {
            accessTypeOptions.push({ label: "Read Only", value: "Read Only" });
          } else if (
            plan.permission_type &&
            plan.permission_type == "Read & Write"
          ) {
            accessTypeOptions.push({
              label: "Read & Write",
              value: "Read & Write",
            });
          }
      this.setState({
        isLoading: false,
        plan,
        accessTypeOptions
      });
    }
  };

  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "accessType",
        order: query.order ? query.order : "desc",
      },
      () => {
        this.fetchStaffPermission();
      }
    );
  };
  fetchStaffPermission = async (loading = true) => {
    const { currentPage, limit, sortBy, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      order: order,
    };
    this.setState({
      isLoading: loading,
    });
    const { staffId } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_PERMISSION.service,
      ApiRoutes.GET_STAFF_PERMISSION.url.replace(":id", staffId),
      ApiRoutes.GET_STAFF_PERMISSION.method,
      ApiRoutes.GET_STAFF_PERMISSION.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const allData = response.data.data;
      console.log("allData", allData);
      const [{ first_name = "", last_name = "", permission = [] }] =
        allData || {};
      this.setState({
        name: [first_name, last_name].filter(Boolean).join(" "),
        permission,
      });
    }
  };
  updatePermission = async (payload, loading = true) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: loading,
      updatingSection: "permission",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_PERMISSION.service,
      ApiRoutes.UPDATE_STAFF_PERMISSION.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_PERMISSION.method,
      ApiRoutes.UPDATE_STAFF_PERMISSION.authenticate,
      undefined,
      payload
    );
    if (response && response.isError) {
      this.setState({
        isUpdating: false,
        updatingSection: "",
        deletingModule: "",
      });
      toast.error(response.messages[0]);
      return false;
    } else {
      await this.fetchStaffPermission(false);
      this.setState({
        isUpdating: false,
        updatingSection: "",
        selectedModule: null,
        accessType: null,
      });
      toast.success(response.messages[0]);
      return true;
    }
  };
  // Handle change on add permission

  handlePermissionSelection = (event, name) => {
    var value = name === "subModules" ? event : event.value;
    if (name === "subModules") {
      var array = [];
      event.forEach((element) => {
        array.push(element.value);
      });
      value = array;
    }

    this.setState({
      newModule: {
        ...this.state.newModule,
        [name]: value,
      },
    });
  };

  addPermission = async (payload) => {
    const { staffId } = this.state;

    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_STAFF_PERMISSION.service,
      ApiRoutes.ADD_STAFF_PERMISSION.url.replace(":id", staffId),
      ApiRoutes.ADD_STAFF_PERMISSION.method,
      ApiRoutes.ADD_STAFF_PERMISSION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      this.fetchStaffPermission();
      this.setState({
        newModule: {
          module: "",
          subModules: null,
          accessType: null,
        },
      });
    }
  };

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { module, accessType, subModules } = this.state.newModule;
    let payload = {
      newModule: {
        module: module,
        accessType: accessType,
        subModules: subModules,
      },
    };
    const { isValid } = permissionValidator(payload);
    if (isValid) {
      this.addPermission(payload);
    }
  };
  // To remove access of particular module
  removePermission = async (moduleName) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to remove ${moduleName} access?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      deletingModule: moduleName,
    });
    await this.updatePermission(
      {
        removedModule: moduleName,
      },
      false
    );
  };

  customBreadCrumb = () => {
    const { isLoading, name } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      name
    );
  };

  setSorting = (sortBy, order) => {
    console.log("sorting", sortBy, order);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    // params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  render() {
    const {
      isLoading,
      permissionErrors,
      deletingModule,
      permission,
      sortBy,
      order,
      newModule,
      accessTypeOptions,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-start justify-content-between pl-0 mb-0 mt-0">
          <h3>
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PermissionFormComponent
              isLoading={isLoading}
              accessTypeOptions={accessTypeOptions}
              newModule={newModule}
              errors={permissionErrors}
              isAdding={this.isAdding}
              handlePermissionSelection={this.handlePermissionSelection}
              onSubmit={this.handleSubmit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AssignedPermissionsListComponent
              isLoading={isLoading}
              permission={permission}
              removePermission={this.removePermission}
              deletingModule={deletingModule}
              setSorting={this.setSorting}
              sortBy={sortBy}
              order={order}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ManageStaffPermission;
