import React, { useState } from "react";
import calender from "../../../assets/img/calenderIcon.svg";
import InputTextFeild from "../form/InputTextFeild";
import rightnexticon from "../../../assets/img/rightnexticon.svg";
import rightbackicon from "../../../assets/img/rightbackicon.svg";
import { Form } from "react-bootstrap";
import { components, DropdownIndicatorProps } from "react-select";
import { areaCode } from "../../../common/phonecode";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../helpers";

const countryState = require("../../../common/countryState.json");

function CompanyDetail({
  onChange,
  handleSubmit,
  customStyles,
  handleBack,
  onCountrySelect,
  onStateSelect,
  onFileChange,
  editStep,
  onCodeSelect,
  loadCodeData,
  allCountry,
  userRegisterData,
  handleSelectChange,
  checkToken,
}) {
  const [stateByCountry, SetStateByCountry] = useState([]);

  const onCountrySelectLocal = (event) => {
    var filterStateList = countryState.filter((x) => x.name === event.value);
    if (filterStateList.length > 0) {
      SetStateByCountry(filterStateList[0].states);
    }
    onCountrySelect(event, "company");
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };

  const customStylesAsync = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "unset",
        border: "0px",
      },
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      border: "0px",
      padding: "0px",
      height: "100%",
      backgroundColor: "transparent",
      boxShadow: "unset",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      maxWidth: "50px",
      padding: "0px",
      backgroundColor: "transparent",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxWidth: "100%",
    }),
  };
  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });

  // areaCode.forEach((element) => {
  //   if (
  //     element.name === "United States" ||
  //     element.name === "Canada" ||
  //     element.name === "Mexico" ||
  //     element.name === "Bahamas"
  //   ) {
  //     if (userRegisterData?.insideUSACanada === true) {
  //       codeData.push({
  //         label: element.dial_code,
  //         value: element.dial_code,
  //       });
  //     }
  //   } else {
  //     if (userRegisterData?.insideUSACanada === false) {
  //       codeData.push({
  //         label: element.dial_code,
  //         value: element.dial_code,
  //       });
  //     }
  //   }
  // });

  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  let stateData = [];
  stateByCountry.forEach((element) => {
    stateData.push({ label: element.name, value: element.name });
  });

  const loadStateData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = stateData.filter(
        (state) =>
          state.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="insideHolder row">
          <div className="inside col-md-6">
            <label htmlFor="inside">
              <input
                type="radio"
                name="insideUSACanada"
                id="inside"
                value="yes"
                checked={userRegisterData?.insideUSACanada}
                onChange={handleSelectChange}
              />{" "}
              Inside USA & Canada
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="outside">
              <input
                type="radio"
                name="insideUSACanada"
                id="outside"
                value="no"
                checked={!userRegisterData?.insideUSACanada}
                onChange={handleSelectChange}
              />{" "}
              Outside USA & Canada
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Company Name*</label>
              <InputTextFeild
                name="companyName"
                type="text"
                placeholder=""
                value={userRegisterData?.companyName}
                disabled={!checkToken ? false : true}
                onChange={!checkToken && onChange}
                // errorMessage={
                //   userRegisterData?.errors &&
                //   userRegisterData?.errors.companyName
                //     ? userRegisterData?.errors.companyName
                //     : ""
                // }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Website*</label>
              <InputTextFeild
                name="website"
                type="text"
                placeholder=""
                value={userRegisterData?.website}
                onChange={onChange}
                errorMessage={
                  userRegisterData?.errors && userRegisterData?.errors.website
                    ? userRegisterData?.errors.website
                    : ""
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Phone Number</label>
              <div className="d-flex form-control group-input companyDeatilContactNum">
                <AsyncSelect
                  cacheOptions
                  components={{ DropdownIndicator }}
                  loadOptions={loadAreaCodeData}
                  styles={customStylesAsync}
                  placeholder="00"
                  className="areaCode"
                  maxMenuHeight={200}
                  width="200px"
                  defaultOptions={codeData}
                  value={
                    userRegisterData?.countryCode
                      ? userRegisterData?.countryCode
                      : +1
                  }
                  name="countryCode"
                  onChange={(selectedOption) => {
                    onCodeSelect(selectedOption, "countryCode");
                  }}
                  isSearchable={true}
                />
                <InputTextFeild
                  name="phone"
                  type="tel"
                  placeholder=""
                  value={userRegisterData?.phone}
                  onChange={onChange}
                  min="0"
                  maxLength="20"
                  errorMessage={
                    userRegisterData?.errors && userRegisterData?.errors.phone
                      ? userRegisterData?.errors.phone
                      : ""
                  }
                />
              </div>
              {!userRegisterData?.countryCode && userRegisterData?.phone && (
                <div className="text-danger">Please enter country code</div>
              )}
              <div>
                {userRegisterData?.errors &&
                userRegisterData?.errors.countryCode
                  ? userRegisterData?.errors.countryCode
                  : ""}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <InputTextFeild
                name="email"
                type="email"
                placeholder=""
                value={userRegisterData?.email}
                // readOnly={!checkToken ? false : true}
                disabled={!checkToken ? false : true}
                onChange={!checkToken && onChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Business Registration Number*</label>
              <InputTextFeild
                name="businessRegNo"
                type="tel"
                placeholder=""
                value={userRegisterData?.businessRegNo}
                onChange={onChange}
                min="5"
                errorMessage={
                  userRegisterData?.errors &&
                  userRegisterData?.errors.businessRegNo
                    ? userRegisterData?.errors.businessRegNo
                    : ""
                }
              />
              {userRegisterData?.businessRegNo &&
                userRegisterData?.businessRegNo.length > 20 && (
                  <div className="text-danger">
                    Number must be less than 20 characters
                  </div>
                )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="businessRegNo_image">
                Upload Business Registration Number*
              </label>
              <div className="customChooseFile">
                {userRegisterData?.businessRegNo_image && (
                  <p className="fileName" style={{textWrap:"nowrap"}}>
                    {userRegisterData?.businessRegNo_image}
                  </p>
                )}
                <span>
                  <input
                    name="businessRegNo_image"
                    type="file"
                    onChange={(evt) =>
                      onFileChange(evt, "company", "businessRegNo_image")
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>VAT or EIN Number*</label>
              <InputTextFeild
                name="VATNumber"
                type="tel"
                placeholder=""
                value={userRegisterData?.VATNumber}
                onChange={onChange}
                errorMessage={
                  userRegisterData?.errors && userRegisterData?.errors.VATNumber
                    ? userRegisterData?.errors.VATNumber
                    : ""
                }
              />
              {userRegisterData?.VATNumber &&
                userRegisterData?.VATNumber.length > 20 && (
                  <div className="text-danger">
                    Number must be less than 20 characters
                  </div>
                )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="VATNumber_image">Upload VAT or EIN Number*</label>
              <div className="customChooseFile">
                {userRegisterData?.VATNumber_image && (
                  <p className="fileName" style={{textWrap:"nowrap"}}>
                    {userRegisterData?.VATNumber_image}
                  </p>
                )}
                <span>
                  <input
                    name="VATNumber_image"
                    type="file"
                    onChange={(evt) =>
                      onFileChange(evt, "company", "VATNumber_image")
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Trade Mark Certification Number*</label>
              <InputTextFeild
                name="TradeMarkCertificateNo"
                type="tel"
                placeholder=""
                value={userRegisterData?.TradeMarkCertificateNo}
                onChange={onChange}
                errorMessage={
                  userRegisterData?.errors &&
                  userRegisterData?.errors.TradeMarkCertificateNo
                    ? userRegisterData?.errors.TradeMarkCertificateNo
                    : ""
                }
              />
              {userRegisterData?.TradeMarkCertificateNo &&
                userRegisterData?.TradeMarkCertificateNo.length > 20 && (
                  <div className="text-danger">
                    Number must be less than 20 characters
                  </div>
                )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="TradeMarkCertificateNo_image">
                Upload Trade Mark Certification Number*
              </label>
              <div className="customChooseFile">
                {userRegisterData?.TradeMarkCertificateNo_image && (
                  <p className="fileName" style={{textWrap:"nowrap"}}>
                    {userRegisterData?.TradeMarkCertificateNo_image}
                  </p>
                )}
                <span>
                  <input
                    name="TradeMarkCertificateNo_image"
                    type="file"
                    onChange={(evt) =>
                      onFileChange(
                        evt,
                        "company",
                        "TradeMarkCertificateNo_image"
                      )
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Country*</label>
              <AsyncSelect
                loadOptions={loadCodeData}
                styles={customStyles}
                placeholder={"Select Country"}
                className="form-control areaCode"
                maxMenuHeight={200}
                defaultOptions={allCountry}
                name="country"
                value={userRegisterData?.country}
                onChange={(selectedOption) => {
                  onCountrySelectLocal(selectedOption, "country");
                }}
                isSearchable={true}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>State*</label>
              {/* <Form.Select
                style={{ customStyles }}
                className="form-control"
                onChange={(selectedOption) => {
                  onStateSelect(selectedOption, "state");
                }}
              >
                <option value="">Select State</option>
                {stateByCountry &&
                  stateByCountry.map((state, index) => (
                    <option key={index} value={state.name}>
                      {state.name}
                    </option>
                  ))}
              </Form.Select> */}
              <AsyncSelect
                loadOptions={loadStateData}
                styles={customStyles}
                placeholder={"Select State"}
                className="form-control areaCode"
                maxMenuHeight={200}
                defaultOptions={stateData}
                name="state"
                value={userRegisterData?.state}
                onChange={(selectedOption) => {
                  onStateSelect(selectedOption, "state");
                }}
                isSearchable={true}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 1*</label>
              <InputTextFeild
                name="address1"
                type="text"
                placeholder=""
                value={userRegisterData?.address1}
                onChange={onChange}
                errorMessage={
                  userRegisterData?.errors && userRegisterData?.errors.address1
                    ? userRegisterData?.errors.address1
                    : ""
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 2*</label>
              <InputTextFeild
                name="address2"
                type="text"
                placeholder=""
                value={userRegisterData?.address2}
                onChange={onChange}
                errorMessage={
                  userRegisterData?.errors && userRegisterData?.errors.address2
                    ? userRegisterData?.errors.address2
                    : ""
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Post Code</label>
              <InputTextFeild
                name="postCode"
                type="tel"
                placeholder=""
                value={userRegisterData?.postCode}
                onChange={onChange}
                min="0"
                maxLength="10"
                errorMessage={
                  userRegisterData?.errors && userRegisterData?.errors.postCode
                    ? userRegisterData?.errors.postCode
                    : ""
                }
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="TradeMarkCertificateNo_image">
                Upload Company Logo
              </label>
              <div className="customChooseFile">
                {userRegisterData?.storeLogo && (
                  <p className="fileName" style={{textWrap:"nowrap"}}>{userRegisterData?.storeLogo}</p>
                )}
                <span>
                  <input
                    name="storeLogo"
                    type="file"
                    onChange={(evt) =>
                      onFileChange(evt, "company", "storeLogo")
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 text-center position-relative"
            style={{ marginTop: "30px" }}
          >
            {editStep !== 2 && (
              <span className="backbutton cursor-pointer" onClick={handleBack}>
                <img src={rightbackicon} /> Back
              </span>
            )}
            <button
              type="submit"
              disabled={
                !userRegisterData?.companyName ||
                !userRegisterData?.email ||
                // !phone ||
                !userRegisterData?.country ||
                !userRegisterData?.website ||
                (userRegisterData?.businessRegNo &&
                  userRegisterData?.businessRegNo.length > 20) ||
                (userRegisterData?.VATNumber &&
                  userRegisterData?.VATNumber.length > 20) ||
                !userRegisterData?.address1 ||
                !userRegisterData?.address2 ||
                // !postCode ||
                // !storeLogo ||
                !userRegisterData?.businessRegNo_image ||
                !userRegisterData?.VATNumber_image ||
                (userRegisterData?.TradeMarkCertificateNo &&
                  userRegisterData?.TradeMarkCertificateNo.length > 20) ||
                !userRegisterData?.TradeMarkCertificateNo_image
                // !state ||
              }
              className="submitButton"
            >
              {editStep ? "Update" : "Next"}
              <img src={rightnexticon} />
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default CompanyDetail;
