import React from "react";
import calender from "../../../assets/img/calenderIcon.svg";
import InputTextFeild from "../../components/form/InputTextFeild";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import rightnexticon from "../../../assets/img/rightnexticon.svg";
import moment from "moment";
import { ApiHelper } from "../../../helpers";
const countryState = require("../../../common/countryState.json");

function OwnerDetails({
  onChange,
  errors,
  onSelect,
  onDateChange,
  handleSubmit,
  birth_date,
  onCountrySelect,
  onFileChange,
  editStep,
  islastNameReadOnly,
  // loadCodeData,
  ownerData,
  isFirstNameReadOnly,
}) {
  let allCountry = [];
  countryState.forEach((element) => {
    allCountry.push({ label: element.name, value: element.name });
  });
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const loadCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>First Name*</label>
              <InputTextFeild
                name="first_name"
                type="text"
                placeholder="First Name"
                value={ownerData.first_name}
                readOnly={isFirstNameReadOnly ? true : false}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Last Name</label>
              <InputTextFeild
                name="last_name"
                type="text"
                placeholder="Last Name"
                value={ownerData.last_name}
                errorMessage={errors && errors.gender ? errors.gender : ""}
                readOnly={islastNameReadOnly ? true : false}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Gender</label>
              <AsyncSelect
                placeholder="Select"
                loadOptions={genderOptions}
                className="form-control p-0"
                maxMenuHeight={200}
                styles={customStyles}
                defaultOptions={genderOptions}
                name="gender"
                value={ownerData?.gender}
                errorMessage={errors && errors.gender ? errors.gender : ""}
                onChange={(selectedOption) => {
                  onSelect(selectedOption, "gender");
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Date of Birth*</label>
              <DatePicker
                className={"form-control"}
                placeholderText={"Select Date"}
                selected={ownerData.birth_date}
                maxDate={moment().subtract(18, "y")._d}
                onChange={onDateChange}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

              <img src={calender} alt="" className="calneder_icon" />

              {birth_date > moment().subtract(18, "years") ? (
                <div className={"text-danger"}>
                  You must be 18 years old or above
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Nationality*</label>
              <AsyncSelect
                loadOptions={loadCodeData}
                styles={customStyles}
                placeholder="Select Country"
                className="form-control areaCode"
                maxMenuHeight={200}
                defaultOptions={allCountry}
                value={ownerData?.country}
                name="country"
                onChange={(selectedOption) => {
                  onCountrySelect(selectedOption, "owner");
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="uploadPhoto">Upload Photo</label>
              <div className="customChooseFile">
                {ownerData?.profile_picture && (
                  <p className="fileName" style={{textWrap:"nowrap"}}>{ownerData?.profile_picture}</p>
                )}
                <span>
                  <input
                    name="uploadPhoto"
                    type="file"
                    onChange={(evt) =>
                      onFileChange(evt, "owner", "profile_picture")
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 text-center position-relative"
            style={{ marginTop: "30px" }}
          >
            <button
              type="submit"
              disabled={
                !ownerData?.country ||
                !ownerData?.birth_date ||
                ownerData?.birth_date > moment().subtract(18, "years")
              }
              className="submitButton"
            >
              {editStep ? "Update" : "Next"}
              <img src={rightnexticon} />
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default OwnerDetails;
