import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { AppRoutes } from "../config";
import Login from "../view/containers/login";
import SetPassword from "../view/containers/SetPassword";
import ForgotPassword from "../view/containers/ForgotPassword";
import ResetPassword from "../view/containers/ResetPassword";
import ChangePassword from "../view/containers/ChangePassword";
import { QRCodeComponent } from "../view/containers/Batches/QRCode";
import PrivateRoute from "./PrivateRouter";
import Invitation from "../view/containers/InviteForm";
import TrackRequest from "../view/components/inviteForm/TrackRequest";
import VerifyIdentity from "../view/containers/VerifyIdentity";
import OtpForm from "../view/components/IdentityForm/otp";
import TermsAndConditionApp from "../view/components/terms&condition/TermsAndConditionApp";
import TermsAndConditionClient from "../view/components/terms&condition/TermsAndConditionClient";
import TermsAndConditionStaff from "../view/components/terms&condition/TermsAndConditionStaff";
import PrivacyPolicyApp from "../view/components/terms&condition/PrivacyPolicyApp";
import PrivacyPolicyClient from "../view/components/terms&condition/PrivacyPolicyClient";
import PrivacyPolicyStaff from "../view/components/terms&condition/PrivacyPolicyStaff";
import TrackEnquiryRequest from "../view/components/inviteForm/TrackEnquiryRequest";
const AppRouter = () => {
  const { pathname } = useLocation();
  return (
    <div id={pathname === AppRoutes.LOGIN ? "" : "wrapper"}>
      <Switch>
        <Route exact path={AppRoutes.LOGIN} component={Login} />
        <Route exact path={AppRoutes.SET_PASSWORD} component={SetPassword} />
        <Route
          exact
          path={AppRoutes.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route
          exact
          path={AppRoutes.RESET_PASSWORD}
          component={ResetPassword}
        />
        <Route
          exact
          path={AppRoutes.CHANGE_PASSWORD}
          component={ChangePassword}
        />
        <Route exact path={AppRoutes.INVITE_FORM} component={Invitation} />
        <Route exact path={AppRoutes.Track_Status} component={TrackRequest} />
        <Route exact path={AppRoutes.Track_Enquiry_Status} component={TrackEnquiryRequest} />
        <Route
          exact
          path={AppRoutes.VERIFYIDENTITY}
          component={VerifyIdentity}
        />
        <Route exact path={AppRoutes.OTPFORM} component={OtpForm} />
        <Route
          exact
          path={AppRoutes.TERMS_AND_CONDITION_APP}
          component={TermsAndConditionApp}
        />
        <Route
          exact
          path={AppRoutes.TERMS_AND_CONDITION_CLIENT}
          component={TermsAndConditionClient}
        />
        <Route
          exact
          path={AppRoutes.TERMS_AND_CONDITION_STAFF}
          component={TermsAndConditionStaff}
        />
        <Route
          exact
          path={AppRoutes.PRIVACY_POLICY_APP}
          component={PrivacyPolicyApp}
        />
        <Route
          exact
          path={AppRoutes.PRIVACY_POLICY_CLIENT}
          component={PrivacyPolicyClient}
        />
        <Route
          exact
          path={AppRoutes.PRIVACY_POLICY_STAFF}
          component={PrivacyPolicyStaff}
        />
        <Route exact path={AppRoutes.PRINT_BATCH} component={QRCodeComponent} />
        <Route path={AppRoutes.HOME} component={PrivateRoute} />
      </Switch>
    </div>
  );
};

export default AppRouter;
