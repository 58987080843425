/** @format */

export const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  REGISTER: {
    service: "/auth",
    url: "/register",
    method: "POST",
    authenticate: false,
  },
  REGISTER_TOKEN_VERIFY: {
    service: "/auth",
    url: "/register?",
    method: "GET",
    authenticate: false,
  },
  SET_PASSWORD: {
    service: "/auth",
    url: "/set-password",
    method: "PATCH",
    authenticate: false,
  },
  FORGOT_PASSWORD: {
    service: "/auth",
    url: "/forgot-password",
    method: "POST",
    authenticate: false,
  },
  RESET_PASSWORD: {
    service: "/auth",
    url: "/reset-password",
    method: "POST",
    authenticate: false,
  },
  CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "PATCH",
    authenticate: false,
  },
  INVITE_FORM: {
    service: "/auth",
    url: "/invite",
    method: "PATCH",
    authenticate: false,
  },
  GET_PROFILE: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  ADD_USER: {
    service: "/users",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  USER_LIST: {
    service: "/users",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_USER_INFO_BY_ID: {
    service: "/users",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  BLOCK_USER: {
    service: "/users",
    url: "/block/:id",
    method: "PATCH",
    authenticate: true,
  },
  UNBLOCK_USER: {
    service: "/users",
    url: "/unblock/:id",
    method: "PATCH",
    authenticate: true,
  },
  SCANS_LIST: {
    service: "/scan",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  FAQS_LIST: {
    service: "/faq",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  PUSHS_LIST: {
    service: "/pushNotifications",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  COUNTERFEIT_LIST: {
    service: "/counterfeit",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_COUNTERFEIT_DETAIL: {
    service: "/counterfeit",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ: {
    service: "/counterfeit",
    url: "/:id/change-assignee",
    method: "PUT",
    authenticate: true,
  },
  UNASSIGN_STAFF_FROM_REQ: {
    service: "/counterfeit",
    url: "/:id/unassign",
    method: "PUT",
    authenticate: true,
  },
  CATEGORIES_LIST: {
    service: "/category",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_CATEGORY: {
    service: "/category",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  DELETE_CATEGORY: {
    service: "/category",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  VIDEOS_LIST: {
    service: "/videos",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  DELETE_VIDEO: {
    service: "/videos",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  ADD_VIDEO: {
    service: "/videos",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_VIDEO: {
    service: "/videos",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_VIDEO_STATUS: {
    service: "/videos",
    url: "/status/:id",
    method: "put",
    authenticate: true,
  },
  ADD_PRODUCT: {
    service: "/product",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_FAQ: {
    service: "/faq",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_PUSH: {
    service: "/pushNotifications",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  PRODUCTS_LIST: {
    service: "/product",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_FAQ: {
    service: "/faq",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_PUSH: {
    service: "/pushNotifications",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  DELETE_FAQ: {
    service: "/faq",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  DELETE_PUSHS: {
    service: "/pushNotifications",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  PRODUCTS_OPTION_LIST: {
    service: "/product",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  ORIGINS_OPTION_LIST: {
    service: "/origin",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  ADD_ORIGIN: {
    service: "/origin",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  ORIGINS_LIST: {
    service: "/origin",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  ADD_BATCH: {
    service: "/batch",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  BATCHES_LIST: {
    service: "/batch",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  BATCH_history: {
    service: "/batch",
    url: "/:id/history",
    method: "GET",
    authenticate: true,
  },
  EXPORT_BATCH: {
    service: "/batch",
    url: "/:id/export",
    method: "GET",
    authenticate: true,
  },
  INACTIVE_BATCH: {
    service: "/batch",
    url: "/mark-as-inactive",
    method: "post",
    authenticate: true,
  },
  DELETE_BATCH_HISTORY: {
    service: "/batch",
    url: "/",
    method: "PATCH",
    authenticate: true,
  },
  ASSIGN_BATCH: {
    service: "/batch",
    url: "/:id/assign",
    method: "POST",
    authenticate: true,
  },
  ASSIGN_BATCH_TO_CLIENT: {
    service: "/batch",
    url: "/assign/client",
    method: "POST",
    authenticate: true,
  },
  EDIT_ASSIGNED_BATCH_OF_CLIENT: {
    service: "/batch",
    url: "/assign/client/:batchGroup",
    method: "PUT",
    authenticate: true,
  },
  FETCH_ASSIGNED_BATCH: {
    service: "/batch",
    url: "/assign",
    method: "GET",
    authenticate: true,
  },
  BATCH_OPTION_LIST: {
    service: "/batch",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  UPLOAD_FILES: {
    service: "/upload",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_CLIENTS: {
    service: "/clients",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VERIFY_CLIENT_INVITATION: {
    service: "/clients",
    url: "/verify-link",
    method: "POST",
    authenticate: false,
  },
  RESEND_INVITATION_TO_CLIENT: {
    service: "/clients",
    url: "/resend-invitation/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_CLIENTS_REQUEST: {
    service: "/clients",
    url: "/requests",
    method: "GET",
    authenticate: true,
  },
  GET_CLIENTS: {
    service: "/clients",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_CLIENT_OPTION_LIST: {
    service: "/clients",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  VIEW_CLIENT: {
    service: "/clients",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  SUBMIT_CLIENT_FORM: {
    service: "/clients",
    url: "/submit/:id",
    method: "PUT",
    authenticate: false,
  },
  UPDATE_CLIENT_INFO: {
    service: "/clients",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STORE_INFO: {
    service: "/clients",
    url: "/store/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_CLIENT_PERMISSION: {
    service: "/clients",
    url: "/:id/permission",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CLIENT_PERMISSION: {
    service: "/clients",
    url: "/permission/:id",
    method: "PATCH",
    authenticate: true,
  },
  UPDATE_CLIENT_STATUS: {
    service: "/clients",
    url: "/status/:id",
    method: "patch",
    authenticate: true,
  },
  FETCH_CLIENT_ASSIGNED_BATCH: {
    service: "/clients",
    url: "/:id/batch-history",
    method: "GET",
    authenticate: true,
  },
  MARK_CLIENT_BATCH_PRINTED: {
    service: "/batch",
    url: "/:id/mark-as-print",
    method: "POST",
    authenticate: true,
  },
  MARK_INACTIVE_CLIENT_BATCH: {
    service: "/batch",
    url: "/mark-as-inactive",
    method: "POST",
    authenticate: true,
  },
  UNASSIGNED_BATCH_FROM_CLIENT: {
    service: "/batch",
    url: "/unassign/:id",
    method: "PUT",
    authenticate: true,
  },
  UNASSIGNED_ALL_BATCH_FROM_CLIENT: {
    service: "/batch",
    url: "/unassign/bulk",
    method: "PUT",
    authenticate: true,
  },
  BATCH_HISTORY_BY_PRODUCT: {
    service: "/batch",
    url: "/history-by-product",
    method: "GET",
    authenticate: true,
  },
  FETCH_ASIGNED_BATCH_BY_PRODUCT: {
    service: "/batch",
    url: "/assigned-by-product",
    method: "GET",
    authenticate: true,
  },
  ADD_STAFF: {
    service: "/staff",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VERIFY_STAFF_INVITATION: {
    service: "/staff",
    url: "/verify-link",
    method: "POST",
    authenticate: true,
  },
  GET_STAFF_REQUEST: {
    service: "/staff",
    url: "/requests",
    method: "GET",
    authenticate: true,
  },
  GET_STAFF: {
    service: "/staff",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_ROLE: {
    service: "/role",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_ROLE: {
    service: "/role",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  EDIT_ROLE: {
    service: "/role",
    url: "/:id",
    method: "PATCH",
    authenticate: true,
  },
  DELETE_ROLE: {
    service: "/role",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_STAFF_OPTION_LIST: {
    service: "/staff",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  VIEW_STAFF: {
    service: "/staff",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  SUBMIT_STAFF_FORM: {
    service: "/staff",
    url: "/submit/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STAFF_INFO: {
    service: "/staff",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STAFF_STATUS: {
    service: "/staff",
    url: "/status/:id",
    method: "patch",
    authenticate: true,
  },
  RESEND_INVITATION_TO_STAFF: {
    service: "/staff",
    url: "/resend-invitation/:id",
    method: "PUT",
    authenticate: true,
  },
  REQUEST_FOR_MODIFICATION_TO_STAFF: {
    service: "/staff",
    url: "/request-for-modification/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_STAFF_PERMISSION: {
    service: "/staff",
    url: "/:id/permission",
    method: "GET",
    authenticate: true,
  },
  UPDATE_STAFF_PERMISSION: {
    service: "/staff",
    url: "/permission/:id",
    method: "PATCH",
    authenticate: true,
  },
  ADD_STAFF_PERMISSION: {
    service: "/staff",
    url: "/permission/:id",
    method: "PATCH",
    authenticate: true,
  },
  DASHBOARD_MEMEBR_STATISTICS: {
    service: "/dashboard",
    url: "/member-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_SCAN_STATISTICS: {
    service: "/dashboard",
    url: "/scan-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_Counterfeits_STATISTICS: {
    service: "/dashboard",
    url: "/counterfeit-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_TOP_SCANNED_PRODUCT: {
    service: "/dashboard",
    url: "/top-scanned-products",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_ACTIVE_USER_LOCATION: {
    service: "/dashboard",
    url: "/active-user-locations",
    method: "GET",
    authenticate: true,
  },
  GET_NOTIFICATIONS: {
    service: "/notifications",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_UPCOMING_EVENT: {
    service: "/",
    url: "plan",
    method: "GET",
    authenticate: true,
  },
  ADD_EVENT: {
    service: "/plan",
    url: "",
    method: "POST",
    authenticate: true,
  },
  RESET_NOTIFICATION_COUNT: {
    service: "/notifications",
    url: "/resetCount",
    method: "patch",
    authenticate: true,
  },
  NOTIFICATION_MARK_READ: {
    service: "/notifications/read",
    url: "/:id",
    method: "patch",
    authenticate: true,
  },
  CLEAR_ALL_NOTIFICATIONS: {
    service: "/notifications",
    url: "/clearAll",
    method: "PUT",
    authenticate: true,
  },
  DELETE_NOTIFICATION: {
    service: "/notifications",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  DELETE_CLIENT: {
    service: "/clients",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  CHANGE_COUNTERFEIT_REQUEST_STATUS: {
    service: "/counterfeit",
    url: "/change-request-status/:id",
    method: "put",
    authenticate: true,
  },
  GET_SETTINGS: {
    service: "/settings",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  SAVE_SETTINGS: {
    service: "/settings",
    url: "/",
    method: "put",
    authenticate: true,
  },
  REPORTS_BATCHES_CARDS: {
    service: "/report/batches/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_CARDS: {
    service: "/report/users/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_PRODUCT_CARDS: {
    service: "/report/product/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_COUNTERFEIT_CARDS: {
    service: "/report/counterfeit/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_SCAN_CARDS: {
    service: "/report/scan/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_SCAN_TABLE: {
    service: "/report/scan",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_BATCHES_TABLE: {
    service: "/report/batches/table",
    url: "",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_BATCHES_TABLE: {
    service: "/report/batches",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_USERS_TABLE: {
    service: "/report/users/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_USERS_TABLE: {
    service: "/report/users",
    url: "/os-export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_PRODUCT_TABLE: {
    service: "/report/product/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_PRODUCT_TABLE: {
    service: "/report/product",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_COUNTERFEIT_TABLE: {
    service: "/report/counterfeit/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_COUNTERFEIT_TABLE: {
    service: "/report/counterfeit",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_SCAN_TABLE: {
    service: "/report/scan/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_GRAPH_OS: {
    service: "/report/users/os-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_GRAPH_LOCATION: {
    service: "/report/users/location-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_SCAN_GRAPH_PRODUCTS: {
    service: "/report/scan/products-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  ID_FORMAT: {
    service: "/clients",
    url: "/idformat",
    method: "patch",
    authenticate: true,
  },
  NOTIFICATION_RESTRICT: {
    service: "/clients",
    url: "/notification",
    method: "patch",
    authenticate: true,
  },
  CATEGORIES_LIST_STATUS: {
    service: "/category",
    url: "/status/:id",
    method: "PUT",
    authenticate: true,
  },
  EVENT_DETAIL: {
    service: "/plan",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  UPDATE_EVENT_DETAIL: {
    service: "/plan",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_EVENT_DETAIL: {
    service: "/plan",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  GET_CLIENT_SUBSCRIPTION: {
    service: "/clientSubscriptions",
    url: "",
    method: "get",
    authenticate: true,
  },
  CHOOSE_CLIENT_SUBSCRIPTION: {
    service: "/clientSubscriptions",
    url: "",
    method: "post",
    authenticate: true,
  },
  CHOOSE_SUBSCRIPTION: {
    service: "/subscriptions/",
    url: "",
    method: "get",
    authenticate: true,
  },
  SUBSCRIPTION_DASHBOARD: {
    service: "/subscriptionDashboard",
    url: "",
    method: "get",
    authenticate: true,
  },
  CLIENT_SUBSCRIPTION_STATUS: {
    service: "/clientSubscriptions",
    url: "/:id/status",
    method: "patch",
    authenticate: true,
  },
  ADD_CARD: {
    service: "/stripe/card",
    url: "",
    method: "post",
    authenticate: true,
  },
  GET_CARDS: {
    service: "/stripe/card",
    url: "",
    method: "get",
    authenticate: true,
  },
  DELETE_CARDS: {
    service: "/stripe/card",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  PRIMARY_CARDS: {
    service: "/stripe/card",
    url: "/:id",
    method: "patch",
    authenticate: true,
  },
  GET_INVOICE: {
    service: "/stripe/invoice",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  GET_UPCOMING_INVOICE: {
    service: "/stripe/upcomingInvoice",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  GET_DISCOUNT: {
    service: "/settings",
    url: "/discount",
    method: "get",
    authenticate: true,
  },
  UPDATE_CLIENT_PIC: {
    service: "/clients",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  CONTACT_ADMIN: {
    service: "/clientSubscriptions",
    url: "/contact",
    method: "POST",
    authenticate: true,
  },
  GET_TRACK_STATUS: {
    service: "/auth/client-status",
    url: "",
    method: "Post",
    authenticate: true,
  },
  GET_ENQUIRY_TRACK_STATUS: {
    service: "/auth/client-enquiry-status",
    url: "",
    method: "Post",
    authenticate: true,
  },
  GET_FAQ: {
    service: "/faq/",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_FAQ_CAT: {
    service: "/faqCategory/",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_VIDEO_TUTORIAL: {
    service: "/videoTutorials/",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_ACCOUNT_SETTINGS: {
    service: "/clients",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CLIENT_ADDRESS: {
    service: "/clients/address",
    url: "/",
    method: "Patch",
    authenticate: true,
  },
  ADD_CLIENT_EMAIL: {
    service: "/auth/send-otp",
    url: "/",
    method: "post",
    authenticate: true,
  },
  VERIFY_EMAIL: {
    service: "/auth/verify",
    url: "/",
    method: "post",
    authenticate: true,
  },
  GET_CONTACT: {
    service: "/clients/contact",
    url: "/",
    method: "get",
    authenticate: true,
  },
  GET_TERMS_PRIVACY: {
    service: "/faq",
    url: "/:key",
    method: "get",
    authenticate: false,
  },
  GET_CLIENT_ADDRESS: {
    service: "/clients/address",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_SIGNIN_DETAIL: {
    service: "/clients/activeUser",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  DELETE_SIGNIN_DETAIL: {
    service: "/clients/removeUser",
    url: "/:id",
    method: "Delete",
    authenticate: true,
  },
  DELETE_EMAIL: {
    service: "/auth/deleteEmailAndPhone",
    url: "",
    method: "Delete",
    authenticate: true,
  },
  SET_PRIMARY_EMAIL: {
    service: "/auth/set-default-email-phone",
    url: "",
    method: "Patch",
    authenticate: true,
  },
  GET_GOOGLE_AUTH: {
    service: "/auth/verify-auth",
    url: "",
    method: "POST",
    authenticate: true,
  },
  GOOGLE_AUTH_ENABLE: {
    service: "/auth/google-auth",
    url: "",
    method: "post",
    authenticate: true,
  },
  GOOGLE_AUTH_DISABLE: {
    service: "/auth/disable-auth",
    url: "",
    method: "patch",
    authenticate: true,
  },
  PHONE_AUTH_OTP: {
    service: "/auth/phoneEmail-auth",
    url: "",
    method: "Post",
    authenticate: false,
  },
  OTP_LOGIN: {
    service: "/auth/verify-phoneEmail-auth",
    url: "",
    method: "Post",
    authenticate: false,
  },
  CLIENT_EMAIL_ALERT: {
    service: "/auth/email-alert",
    url: "",
    method: "Patch",
    authenticate: true,
  },
  LOGOUT: {
    service: "/auth/logout",
    url: "",
    method: "POST",
    authenticate: true,
  },
  GOOGLE_VERIFY_LOGIN: {
    service: "/auth/verify-google-auth",
    url: "",
    method: "POST",
    authenticate: true,
  },
  ACTIVE_USER_SESSION: {
    service: "/clients/activeUser",
    url: "",
    method: "GET",
    authenticate: true,
  },
  DELETE_USER_SESSION: {
    service: "/clients/removeUser",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_DEVICE_HISTORY: {
    service: "/clients/deviceHistory",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_DATE_FORMAT_LIST: {
    service: "/auth/dateFormatOptionList",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_DATE_TIME_FORMAT: {
    service: "/auth/date-time-format",
    url: "/",
    method: "Patch",
    authenticate: true,
  },
  DELETE_ACCOUNT: {
    service: "/clients",
    url: "/",
    method: "Delete",
    authenticate: true,
  },
  SOCIAL_LOGIN_AUTH: {
    service: "/auth/social-login",
    url: "",
    method: "POST",
    authenticate: true,
  },
  SOCIAL_LOGIN: {
    service: "/clients/addSocialLogin",
    url: "",
    method: "POST",
    authenticate: true,
  },
  SOCIAL_LOGIN_DETAILS: {
    service: "/clients/socialLoginDetails",
    url: "",
    method: "GET",
    authenticate: true,
  },
  DELETE_SOCIAL_LOGIN_DETAILS: {
    service: "/clients/removeSocialAccount",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_NOTIFICATIONS_ACTIVITY: {
    service: "/notifications",
    url: "/activity",
    method: "GET",
    authenticate: true,
  },
};
